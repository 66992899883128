var githubDarkHighContrast = Object.freeze({
  "colors": {
    "activityBar.activeBorder": "#ff967d",
    "activityBar.background": "#0a0c10",
    "activityBar.border": "#7a828e",
    "activityBar.foreground": "#f0f3f6",
    "activityBar.inactiveForeground": "#f0f3f6",
    "activityBarBadge.background": "#409eff",
    "activityBarBadge.foreground": "#0a0c10",
    "badge.background": "#409eff",
    "badge.foreground": "#0a0c10",
    "breadcrumb.activeSelectionForeground": "#f0f3f6",
    "breadcrumb.focusForeground": "#f0f3f6",
    "breadcrumb.foreground": "#f0f3f6",
    "breadcrumbPicker.background": "#272b33",
    "button.background": "#09b43a",
    "button.foreground": "#0a0c10",
    "button.hoverBackground": "#26cd4d",
    "button.secondaryBackground": "#4c525d",
    "button.secondaryForeground": "#f0f3f6",
    "button.secondaryHoverBackground": "#525964",
    "checkbox.background": "#272b33",
    "checkbox.border": "#7a828e",
    "debugConsole.errorForeground": "#ffb1af",
    "debugConsole.infoForeground": "#bdc4cc",
    "debugConsole.sourceForeground": "#f7c843",
    "debugConsole.warningForeground": "#f0b72f",
    "debugConsoleInputIcon.foreground": "#cb9eff",
    "debugIcon.breakpointForeground": "#ff6a69",
    "debugTokenExpression.boolean": "#4ae168",
    "debugTokenExpression.error": "#ffb1af",
    "debugTokenExpression.name": "#91cbff",
    "debugTokenExpression.number": "#4ae168",
    "debugTokenExpression.string": "#addcff",
    "debugTokenExpression.value": "#addcff",
    "debugToolBar.background": "#272b33",
    "descriptionForeground": "#f0f3f6",
    "diffEditor.insertedLineBackground": "#09b43a26",
    "diffEditor.insertedTextBackground": "#26cd4d4d",
    "diffEditor.removedLineBackground": "#ff6a6926",
    "diffEditor.removedTextBackground": "#ff94924d",
    "dropdown.background": "#272b33",
    "dropdown.border": "#7a828e",
    "dropdown.foreground": "#f0f3f6",
    "dropdown.listBackground": "#272b33",
    "editor.background": "#0a0c10",
    "editor.findMatchBackground": "#e09b13",
    "editor.findMatchHighlightBackground": "#fbd66980",
    "editor.focusedStackFrameHighlightBackground": "#09b43a",
    "editor.foldBackground": "#9ea7b31a",
    "editor.foreground": "#f0f3f6",
    "editor.inactiveSelectionBackground": "#9ea7b3",
    "editor.lineHighlightBackground": "#9ea7b31a",
    "editor.lineHighlightBorder": "#71b7ff",
    "editor.linkedEditingBackground": "#71b7ff12",
    "editor.selectionBackground": "#ffffff",
    "editor.selectionForeground": "#0a0c10",
    "editor.selectionHighlightBackground": "#26cd4d40",
    "editor.stackFrameHighlightBackground": "#e09b13",
    "editor.wordHighlightBackground": "#9ea7b380",
    "editor.wordHighlightBorder": "#9ea7b399",
    "editor.wordHighlightStrongBackground": "#9ea7b34d",
    "editor.wordHighlightStrongBorder": "#9ea7b399",
    "editorBracketHighlight.foreground1": "#91cbff",
    "editorBracketHighlight.foreground2": "#4ae168",
    "editorBracketHighlight.foreground3": "#f7c843",
    "editorBracketHighlight.foreground4": "#ffb1af",
    "editorBracketHighlight.foreground5": "#ffadd4",
    "editorBracketHighlight.foreground6": "#dbb7ff",
    "editorBracketHighlight.unexpectedBracket.foreground": "#f0f3f6",
    "editorBracketMatch.background": "#26cd4d40",
    "editorBracketMatch.border": "#26cd4d99",
    "editorCursor.foreground": "#71b7ff",
    "editorGroup.border": "#7a828e",
    "editorGroupHeader.tabsBackground": "#010409",
    "editorGroupHeader.tabsBorder": "#7a828e",
    "editorGutter.addedBackground": "#09b43a",
    "editorGutter.deletedBackground": "#ff6a69",
    "editorGutter.modifiedBackground": "#e09b13",
    "editorIndentGuide.activeBackground": "#f0f3f63d",
    "editorIndentGuide.background": "#f0f3f61f",
    "editorInlayHint.background": "#bdc4cc33",
    "editorInlayHint.foreground": "#f0f3f6",
    "editorInlayHint.paramBackground": "#bdc4cc33",
    "editorInlayHint.paramForeground": "#f0f3f6",
    "editorInlayHint.typeBackground": "#bdc4cc33",
    "editorInlayHint.typeForeground": "#f0f3f6",
    "editorLineNumber.activeForeground": "#f0f3f6",
    "editorLineNumber.foreground": "#9ea7b3",
    "editorOverviewRuler.border": "#010409",
    "editorWhitespace.foreground": "#7a828e",
    "editorWidget.background": "#272b33",
    "errorForeground": "#ff6a69",
    "focusBorder": "#409eff",
    "foreground": "#f0f3f6",
    "gitDecoration.addedResourceForeground": "#26cd4d",
    "gitDecoration.conflictingResourceForeground": "#e7811d",
    "gitDecoration.deletedResourceForeground": "#ff6a69",
    "gitDecoration.ignoredResourceForeground": "#9ea7b3",
    "gitDecoration.modifiedResourceForeground": "#f0b72f",
    "gitDecoration.submoduleResourceForeground": "#f0f3f6",
    "gitDecoration.untrackedResourceForeground": "#26cd4d",
    "icon.foreground": "#f0f3f6",
    "input.background": "#0a0c10",
    "input.border": "#7a828e",
    "input.foreground": "#f0f3f6",
    "input.placeholderForeground": "#9ea7b3",
    "keybindingLabel.foreground": "#f0f3f6",
    "list.activeSelectionBackground": "#9ea7b366",
    "list.activeSelectionForeground": "#f0f3f6",
    "list.focusBackground": "#409eff26",
    "list.focusForeground": "#f0f3f6",
    "list.highlightForeground": "#71b7ff",
    "list.hoverBackground": "#9ea7b31a",
    "list.hoverForeground": "#f0f3f6",
    "list.inactiveFocusBackground": "#409eff26",
    "list.inactiveSelectionBackground": "#9ea7b366",
    "list.inactiveSelectionForeground": "#f0f3f6",
    "minimapSlider.activeBackground": "#bdc4cc47",
    "minimapSlider.background": "#bdc4cc33",
    "minimapSlider.hoverBackground": "#bdc4cc3d",
    "notificationCenterHeader.background": "#272b33",
    "notificationCenterHeader.foreground": "#f0f3f6",
    "notifications.background": "#272b33",
    "notifications.border": "#7a828e",
    "notifications.foreground": "#f0f3f6",
    "notificationsErrorIcon.foreground": "#ff6a69",
    "notificationsInfoIcon.foreground": "#71b7ff",
    "notificationsWarningIcon.foreground": "#f0b72f",
    "panel.background": "#010409",
    "panel.border": "#7a828e",
    "panelInput.border": "#7a828e",
    "panelTitle.activeBorder": "#ff967d",
    "panelTitle.activeForeground": "#f0f3f6",
    "panelTitle.inactiveForeground": "#f0f3f6",
    "peekViewEditor.background": "#9ea7b31a",
    "peekViewEditor.matchHighlightBackground": "#e09b13",
    "peekViewResult.background": "#0a0c10",
    "peekViewResult.matchHighlightBackground": "#e09b13",
    "pickerGroup.border": "#7a828e",
    "pickerGroup.foreground": "#f0f3f6",
    "progressBar.background": "#409eff",
    "quickInput.background": "#272b33",
    "quickInput.foreground": "#f0f3f6",
    "scrollbar.shadow": "#7a828e33",
    "scrollbarSlider.activeBackground": "#bdc4cc47",
    "scrollbarSlider.background": "#bdc4cc33",
    "scrollbarSlider.hoverBackground": "#bdc4cc3d",
    "settings.headerForeground": "#f0f3f6",
    "settings.modifiedItemIndicator": "#e09b13",
    "sideBar.background": "#010409",
    "sideBar.border": "#7a828e",
    "sideBar.foreground": "#f0f3f6",
    "sideBarSectionHeader.background": "#010409",
    "sideBarSectionHeader.border": "#7a828e",
    "sideBarSectionHeader.foreground": "#f0f3f6",
    "sideBarTitle.foreground": "#f0f3f6",
    "statusBar.background": "#0a0c10",
    "statusBar.border": "#7a828e",
    "statusBar.debuggingBackground": "#ff6a69",
    "statusBar.debuggingForeground": "#0a0c10",
    "statusBar.focusBorder": "#409eff80",
    "statusBar.foreground": "#f0f3f6",
    "statusBar.noFolderBackground": "#0a0c10",
    "statusBarItem.activeBackground": "#f0f3f61f",
    "statusBarItem.focusBorder": "#409eff",
    "statusBarItem.hoverBackground": "#f0f3f614",
    "statusBarItem.prominentBackground": "#9ea7b366",
    "statusBarItem.remoteBackground": "#525964",
    "statusBarItem.remoteForeground": "#f0f3f6",
    "symbolIcon.arrayForeground": "#fe9a2d",
    "symbolIcon.booleanForeground": "#71b7ff",
    "symbolIcon.classForeground": "#fe9a2d",
    "symbolIcon.colorForeground": "#91cbff",
    "symbolIcon.constantForeground": [
      "#acf7b6",
      "#72f088",
      "#4ae168",
      "#26cd4d",
      "#09b43a",
      "#09b43a",
      "#02a232",
      "#008c2c",
      "#007728",
      "#006222"
    ],
    "symbolIcon.constructorForeground": "#dbb7ff",
    "symbolIcon.enumeratorForeground": "#fe9a2d",
    "symbolIcon.enumeratorMemberForeground": "#71b7ff",
    "symbolIcon.eventForeground": "#9ea7b3",
    "symbolIcon.fieldForeground": "#fe9a2d",
    "symbolIcon.fileForeground": "#f0b72f",
    "symbolIcon.folderForeground": "#f0b72f",
    "symbolIcon.functionForeground": "#cb9eff",
    "symbolIcon.interfaceForeground": "#fe9a2d",
    "symbolIcon.keyForeground": "#71b7ff",
    "symbolIcon.keywordForeground": "#ff9492",
    "symbolIcon.methodForeground": "#cb9eff",
    "symbolIcon.moduleForeground": "#ff9492",
    "symbolIcon.namespaceForeground": "#ff9492",
    "symbolIcon.nullForeground": "#71b7ff",
    "symbolIcon.numberForeground": "#26cd4d",
    "symbolIcon.objectForeground": "#fe9a2d",
    "symbolIcon.operatorForeground": "#91cbff",
    "symbolIcon.packageForeground": "#fe9a2d",
    "symbolIcon.propertyForeground": "#fe9a2d",
    "symbolIcon.referenceForeground": "#71b7ff",
    "symbolIcon.snippetForeground": "#71b7ff",
    "symbolIcon.stringForeground": "#91cbff",
    "symbolIcon.structForeground": "#fe9a2d",
    "symbolIcon.textForeground": "#91cbff",
    "symbolIcon.typeParameterForeground": "#91cbff",
    "symbolIcon.unitForeground": "#71b7ff",
    "symbolIcon.variableForeground": "#fe9a2d",
    "tab.activeBackground": "#0a0c10",
    "tab.activeBorder": "#0a0c10",
    "tab.activeBorderTop": "#ff967d",
    "tab.activeForeground": "#f0f3f6",
    "tab.border": "#7a828e",
    "tab.hoverBackground": "#0a0c10",
    "tab.inactiveBackground": "#010409",
    "tab.inactiveForeground": "#f0f3f6",
    "tab.unfocusedActiveBorder": "#0a0c10",
    "tab.unfocusedActiveBorderTop": "#7a828e",
    "tab.unfocusedHoverBackground": "#9ea7b31a",
    "terminal.ansiBlack": "#7a828e",
    "terminal.ansiBlue": "#71b7ff",
    "terminal.ansiBrightBlack": "#9ea7b3",
    "terminal.ansiBrightBlue": "#91cbff",
    "terminal.ansiBrightCyan": "#56d4dd",
    "terminal.ansiBrightGreen": "#4ae168",
    "terminal.ansiBrightMagenta": "#dbb7ff",
    "terminal.ansiBrightRed": "#ffb1af",
    "terminal.ansiBrightWhite": "#ffffff",
    "terminal.ansiBrightYellow": "#f7c843",
    "terminal.ansiCyan": "#39c5cf",
    "terminal.ansiGreen": "#26cd4d",
    "terminal.ansiMagenta": "#cb9eff",
    "terminal.ansiRed": "#ff9492",
    "terminal.ansiWhite": "#d9dee3",
    "terminal.ansiYellow": "#f0b72f",
    "terminal.foreground": "#f0f3f6",
    "textBlockQuote.background": "#010409",
    "textBlockQuote.border": "#7a828e",
    "textCodeBlock.background": "#9ea7b366",
    "textLink.activeForeground": "#71b7ff",
    "textLink.foreground": "#71b7ff",
    "textPreformat.foreground": "#f0f3f6",
    "textSeparator.foreground": "#7a828e",
    "titleBar.activeBackground": "#0a0c10",
    "titleBar.activeForeground": "#f0f3f6",
    "titleBar.border": "#7a828e",
    "titleBar.inactiveBackground": "#010409",
    "titleBar.inactiveForeground": "#f0f3f6",
    "tree.indentGuidesStroke": "#7a828e",
    "welcomePage.buttonBackground": "#272b33",
    "welcomePage.buttonHoverBackground": "#525964"
  },
  "displayName": "GitHub Dark High Contrast",
  "name": "github-dark-high-contrast",
  "semanticHighlighting": true,
  "tokenColors": [
    {
      "scope": [
        "comment",
        "punctuation.definition.comment",
        "string.comment"
      ],
      "settings": {
        "foreground": "#bdc4cc"
      }
    },
    {
      "scope": [
        "constant.other.placeholder",
        "constant.character"
      ],
      "settings": {
        "foreground": "#ff9492"
      }
    },
    {
      "scope": [
        "constant",
        "entity.name.constant",
        "variable.other.constant",
        "variable.other.enummember",
        "variable.language",
        "entity"
      ],
      "settings": {
        "foreground": "#91cbff"
      }
    },
    {
      "scope": [
        "entity.name",
        "meta.export.default",
        "meta.definition.variable"
      ],
      "settings": {
        "foreground": "#ffb757"
      }
    },
    {
      "scope": [
        "variable.parameter.function",
        "meta.jsx.children",
        "meta.block",
        "meta.tag.attributes",
        "entity.name.constant",
        "meta.object.member",
        "meta.embedded.expression"
      ],
      "settings": {
        "foreground": "#f0f3f6"
      }
    },
    {
      "scope": "entity.name.function",
      "settings": {
        "foreground": "#dbb7ff"
      }
    },
    {
      "scope": [
        "entity.name.tag",
        "support.class.component"
      ],
      "settings": {
        "foreground": "#72f088"
      }
    },
    {
      "scope": "keyword",
      "settings": {
        "foreground": "#ff9492"
      }
    },
    {
      "scope": [
        "storage",
        "storage.type"
      ],
      "settings": {
        "foreground": "#ff9492"
      }
    },
    {
      "scope": [
        "storage.modifier.package",
        "storage.modifier.import",
        "storage.type.java"
      ],
      "settings": {
        "foreground": "#f0f3f6"
      }
    },
    {
      "scope": [
        "string",
        "string punctuation.section.embedded source"
      ],
      "settings": {
        "foreground": "#addcff"
      }
    },
    {
      "scope": "support",
      "settings": {
        "foreground": "#91cbff"
      }
    },
    {
      "scope": "meta.property-name",
      "settings": {
        "foreground": "#91cbff"
      }
    },
    {
      "scope": "variable",
      "settings": {
        "foreground": "#ffb757"
      }
    },
    {
      "scope": "variable.other",
      "settings": {
        "foreground": "#f0f3f6"
      }
    },
    {
      "scope": "invalid.broken",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ffb1af"
      }
    },
    {
      "scope": "invalid.deprecated",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ffb1af"
      }
    },
    {
      "scope": "invalid.illegal",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ffb1af"
      }
    },
    {
      "scope": "invalid.unimplemented",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ffb1af"
      }
    },
    {
      "scope": "carriage-return",
      "settings": {
        "background": "#ff9492",
        "content": "^M",
        "fontStyle": "italic underline",
        "foreground": "#ffffff"
      }
    },
    {
      "scope": "message.error",
      "settings": {
        "foreground": "#ffb1af"
      }
    },
    {
      "scope": "string variable",
      "settings": {
        "foreground": "#91cbff"
      }
    },
    {
      "scope": [
        "source.regexp",
        "string.regexp"
      ],
      "settings": {
        "foreground": "#addcff"
      }
    },
    {
      "scope": [
        "string.regexp.character-class",
        "string.regexp constant.character.escape",
        "string.regexp source.ruby.embedded",
        "string.regexp string.regexp.arbitrary-repitition"
      ],
      "settings": {
        "foreground": "#addcff"
      }
    },
    {
      "scope": "string.regexp constant.character.escape",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#72f088"
      }
    },
    {
      "scope": "support.constant",
      "settings": {
        "foreground": "#91cbff"
      }
    },
    {
      "scope": "support.variable",
      "settings": {
        "foreground": "#91cbff"
      }
    },
    {
      "scope": "support.type.property-name.json",
      "settings": {
        "foreground": "#72f088"
      }
    },
    {
      "scope": "meta.module-reference",
      "settings": {
        "foreground": "#91cbff"
      }
    },
    {
      "scope": "punctuation.definition.list.begin.markdown",
      "settings": {
        "foreground": "#ffb757"
      }
    },
    {
      "scope": [
        "markup.heading",
        "markup.heading entity.name"
      ],
      "settings": {
        "fontStyle": "bold",
        "foreground": "#91cbff"
      }
    },
    {
      "scope": "markup.quote",
      "settings": {
        "foreground": "#72f088"
      }
    },
    {
      "scope": "markup.italic",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#f0f3f6"
      }
    },
    {
      "scope": "markup.bold",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#f0f3f6"
      }
    },
    {
      "scope": [
        "markup.underline"
      ],
      "settings": {
        "fontStyle": "underline"
      }
    },
    {
      "scope": [
        "markup.strikethrough"
      ],
      "settings": {
        "fontStyle": "strikethrough"
      }
    },
    {
      "scope": "markup.inline.raw",
      "settings": {
        "foreground": "#91cbff"
      }
    },
    {
      "scope": [
        "markup.deleted",
        "meta.diff.header.from-file",
        "punctuation.definition.deleted"
      ],
      "settings": {
        "background": "#ad0116",
        "foreground": "#ffb1af"
      }
    },
    {
      "scope": [
        "punctuation.section.embedded"
      ],
      "settings": {
        "foreground": "#ff9492"
      }
    },
    {
      "scope": [
        "markup.inserted",
        "meta.diff.header.to-file",
        "punctuation.definition.inserted"
      ],
      "settings": {
        "background": "#006222",
        "foreground": "#72f088"
      }
    },
    {
      "scope": [
        "markup.changed",
        "punctuation.definition.changed"
      ],
      "settings": {
        "background": "#a74c00",
        "foreground": "#ffb757"
      }
    },
    {
      "scope": [
        "markup.ignored",
        "markup.untracked"
      ],
      "settings": {
        "background": "#91cbff",
        "foreground": "#272b33"
      }
    },
    {
      "scope": "meta.diff.range",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#dbb7ff"
      }
    },
    {
      "scope": "meta.diff.header",
      "settings": {
        "foreground": "#91cbff"
      }
    },
    {
      "scope": "meta.separator",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#91cbff"
      }
    },
    {
      "scope": "meta.output",
      "settings": {
        "foreground": "#91cbff"
      }
    },
    {
      "scope": [
        "brackethighlighter.tag",
        "brackethighlighter.curly",
        "brackethighlighter.round",
        "brackethighlighter.square",
        "brackethighlighter.angle",
        "brackethighlighter.quote"
      ],
      "settings": {
        "foreground": "#bdc4cc"
      }
    },
    {
      "scope": "brackethighlighter.unmatched",
      "settings": {
        "foreground": "#ffb1af"
      }
    },
    {
      "scope": [
        "constant.other.reference.link",
        "string.other.link"
      ],
      "settings": {
        "foreground": "#addcff"
      }
    }
  ],
  "type": "dark"
});

export { githubDarkHighContrast as default };
